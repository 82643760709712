
import UiContent from '@/components/ui/sections/Content'

export default {
  name: 'BuilderContentContent',
  components: {
    UiContent
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
