
import UiFactsBox from '@/components/ui/sections/FactsBox'

export default {
  name: 'BuilderFactsBox',
  components: {
    UiFactsBox
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
