
// @todo implement floating labels for better usability https://getbootstrap.com/docs/5.0/forms/floating-labels/

import FormMixin from '@/mixins/form'
import UiFormInputCheckbox from '@/components/ui/form/input/Checkbox'
import UiFormInputEmail from '@/components/ui/form/input/Email'
import UiFormInputFile from '@/components/ui/form/input/File'
import UiFormInputHidden from '@/components/ui/form/input/Hidden'
import UiFormInputSelect from '@/components/ui/form/input/Select'
import UiFormInputText from '@/components/ui/form/input/Text'
import UiFormInputTextarea from '@/components/ui/form/input/Textarea'
import UiFormInputPhone from '@/components/ui/form/input/Phone'
import UiFormInputUrl from '@/components/ui/form/input/Url'

export default {
  name: 'UiForm',
  components: {
    UiFormInputCheckbox,
    UiFormInputEmail,
    UiFormInputFile,
    UiFormInputHidden,
    UiFormInputSelect,
    UiFormInputText,
    UiFormInputTextarea,
    UiFormInputPhone,
    UiFormInputUrl,
  },
  mixins: [
    FormMixin
  ],
  props: {
    mutation: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    additionalFormData: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    formDataTransformer: {
      type: Function,
      required: false,
      default: (data) => data
    },
    fileIdsByFieldTransformer: {
      type: Function,
      required: false,
      default: (data) => data
    },
    whiteBackground: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
