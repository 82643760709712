
import InfiniteLoading from 'vue-infinite-loading'
import UiHeader from '@/components/ui/sections/Header'
import UiBlogListPosts from '@/components/ui/blog/ListPosts'

export default {
  name: 'BlogIndex',
  components: {
    UiHeader,
    UiBlogListPosts,
    InfiniteLoading
  },
  async asyncData( context ) {

    try {
      const { useList } = await import('@/datalayer/blog/post')
      const { useCount } = await import('@/datalayer/blog/post')
      const count = await useCount(context)
      const items = await useList(context, 9, 1)

      if (!items.length) {
        context.res?.setHeader('Cache-Control', 'public, no-cache, max-age=0, s-maxage=0')
        return context.error({ statusCode: 404, message: 'No posts found' })
      }
      context.res?.setHeader('Cache-Control', 'public, max-age=0, s-maxage=' + 60 * 60 * 24 * 7)
      return {
        items,
        page: 1,
        count
      }
    } catch (e) {
      context.res?.setHeader('Cache-Control', 'public, no-cache, max-age=0, s-maxage=0')
      return context.error({ statusCode: 500, message: 'Error loading blog posts' })
    }
  },
  data () {
    return {
      items: [],
      count: 0
    }
  },
  head () {
    return {
      title: 'Blog',
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: 'Blog'
        }
      ]
    }
  },
  methods: {
    async infinite ($state) {
      this.page += 1
      const { useList } = await import('@/datalayer/blog/post')
      const items = await useList(this, 9, this.page)
      this.items = this.items.concat(items)

      if (this.items.length === this.count) {
        $state.complete()
      } else if (items.length === 9) {
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  }
}
