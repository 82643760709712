import { render, staticRenderFns } from "./default.vue?vue&type=template&id=04d469a4"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderNavigation: require('/home/node/app/components/HeaderNavigation.vue').default,FooterMain: require('/home/node/app/components/FooterMain.vue').default,FooterBottom: require('/home/node/app/components/FooterBottom.vue').default})
