
import Wysiwyg from '@/mixins/wysiwyg'
import Image from '@/mixins/image'
import UiTextAndImageList from '@/components/ui/sections/TextAndImageList'

export default {
  name: 'BuilderContentTextAndImageList',
  components: {
    UiTextAndImageList
  },
  mixins: [
    Wysiwyg,
    Image
  ],
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
}
