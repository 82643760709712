
import UiImageGrid from '@/components/ui/grids/ImageGrid'

export default {
  name: 'BuilderPartnerGrid',
  components: {
    UiImageGrid
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
  data () {
    return {
      cmsPages: []
    }
  },
  async fetch () {
    if (!this.cmsPages.length) {
      const { useList } = await import('@/datalayer/cms/page')
      const cmsPageIds = []
      for (let i = 0; i < this.section.partners.length; i++) {
        if (this.section.partners[i].partner.cms_page) {
          cmsPageIds.push(this.section.partners[i].partner.cms_page)
        }
      }
      this.cmsPages = await useList(this, this.section.partners.length, 1, cmsPageIds)
    }
  },
  computed: {
    images () {
      return this.section.partners.filter(item => {
        return (this.section?.styles?.white_background && item.partner?.logo_transparent_black)
          || (!this.section?.styles?.white_background && item.partner?.logo_transparent_white)
      }).map(item => {
        const logo = this.section?.styles?.white_background ? item.partner.logo_transparent_black : item.partner.logo_transparent_white
        const target = this.cmsPages.find(cmsPage => item.partner.cms_page === cmsPage.id)
        return {...logo, ...{ alternativeText: item.partner.name, target: target?.slug }}
      })
    }
  }
}
