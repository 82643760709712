
import UiCaseStudyPage from '@/components/ui/case-study/PageViewWithTags'
import CaseStudyLoader from '@/mixins/caseStudyloader'

export default {
  name: 'CaseStudyIndex',
  components: {
    UiCaseStudyPage
  },
  mixins: [
    CaseStudyLoader
  ],
  scrollToTop: true
}
