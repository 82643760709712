
import unescape from 'lodash/unescape'
import ImageMixin from '@/mixins/image'
import UiButton from '@/components/ui/elements/Button'

export default {
  name: 'UiLargeCallToAction',
  components: {
    UiButton
  },
  mixins: [
    ImageMixin,
  ],
  props: {
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    button: {
      type: Object,
      required: true
    },
    background: {
      type: Object,
      default () {
        return {}
      }
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
      imageState: 'loading',
      asyncImage: typeof window !== 'undefined' ? new Image() : null
    }
  },
  computed: {
    cleanTitle () {
      return this?.title ? unescape(this.title.replace(/(<([^>]+)>)/gi, '').trim()) : ''
    },
    cleanContent () {
      return unescape(this.content)
    },
    classes () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      if (this.hasBackgroundStyles) {
        classes.push('background')
      }
      if (this.isDark) {
        classes.push('dark')
      }
      return classes.join(' ')
    },
    isDark () {
      return this?.background?.color ? this.lightOrDark(this.background.color) : !this.styles?.white_background
    },
    hasBackgroundStyles () {
      return this.background?.color || this.background?.image?.url
    },
    computedStyle() {
      const styles = []
      if (this.background?.color) {
        styles.push('background-color:' + this.background.color + ';')
      }
      if (this.imageState === 'loaded') {
        styles.push('background-image: url(' + this.asyncImage.src + ');')
      }
      return styles.join(' ')
    },
    textShadow () {
      if (this?.background?.color || this.background?.image) {
        return 'text-shadow: 1px 1px 3px ' + (this.isDark ? '#fff' : '#000') + ';'
      }
      return ''
    }
  },
  mounted () {
    if (typeof window !== 'undefined' && this.background?.image?.url) {
      this.$nextTick(() => {
        this.fetchImage()
      })
    }
  },
  methods: {
    fetchImage () {
      this.asyncImage.onload = this.imageOnLoad
      this.asyncImage.onerror = this.imageOnError
      this.imageState = 'loading'
      const resizedUrl = this.getResizedImage(this.background.image.url, {
        format: 'webp',
        fit: 'cover',
        quality: '50',
        width: 1200,
        height: 400
      })
      this.asyncImage.src = resizedUrl.url
    },
    imageOnLoad () {
      this.imageState = 'loaded'
    },
    imageOnError () {
      this.imageState = 'error'
    }
  }
}
