
import Animation from '@/mixins/animation'

export default {
  name: 'NoHeaderLayout',
  components: {
    FooterMain: () => import('@/components/FooterMain'),
    FooterBottom: () => import('@/components/FooterBottom'),
    // ScrollProgress: () => import('@/components/widgets/ScrollProgress'),
  },
  mixins: [
    Animation
  ],
  head () {
    let normalisedPath = this.$route.path
    normalisedPath = normalisedPath.replace(/\/$/, '')
    normalisedPath = (normalisedPath.endsWith('/') ? normalisedPath.slice(0, -1) : normalisedPath)

    normalisedPath = normalisedPath.includes('/tags/') ? normalisedPath.slice(0, normalisedPath.indexOf('/tags/')) : normalisedPath

    return {
      titleTemplate: '%s - AYKO',
      link: [
        {
          rel: 'canonical',
          href: normalisedPath ? (this.$config.NUXT_URL + normalisedPath) : (this.$config.NUXT_URL + '/')
        }
      ]
    }
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.history.scrollRestoration = 'auto';
    }
  },
}
