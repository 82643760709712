
import Animation from '@/mixins/animation'
import PageBuilderComponentLoader from '@/mixins/pageBuilderComponentLoader'
import UiHeader from '@/components/ui/sections/Header'
import UiFullWidthImage from '@/components/ui/sections/FullWidthImage'
import UiFullWidthVideo from '@/components/ui/sections/FullWidthVideo'
import UiImage from '@/components/ui/sections/Image'

export default {
  name: 'CaseStudyView',
  components: {
    UiHeader,
    UiFullWidthImage,
    UiFullWidthVideo,
    UiImage
  },
  mixins: [
    Animation,
    PageBuilderComponentLoader
  ],
  async asyncData(context) {
    const { slug } = context.params
    const { useContent } = await import('@/datalayer/casestudy')
    const { caseStudy, relatedCaseStudies, meta, jsonld } = await useContent(context, slug)
    return { caseStudy, relatedCaseStudies, meta, jsonld }
  },
  data () {
    return {
      loading: 0
    }
  },
  head () {
    return this.meta
  },
  jsonld () {
    return this.jsonld
  }
}
