
import UiFullWidthVideo from '@/components/ui/sections/FullWidthVideo'

export default {
  name: 'BuilderMediaFullWidthVideo',
  components: {
    UiFullWidthVideo
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
}
