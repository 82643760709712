
import UiImage from '@/components/ui/sections/Image'

export default {
  name: 'BuilderContentImage',
  components: {
    UiImage
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
