
import UiAnimatedTextGrid from '@/components/ui/grids/AnimatedTextGrid'
export default {
  name: 'BuilderProcessGrid',
  components: {
    UiAnimatedTextGrid
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
