
import UiTextAndIconGrid from '@/components/ui/sections/TextAndIconGrid'

export default {
  name: 'BuilderTextAndIconGrid',
  components: {
    UiTextAndIconGrid
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  }
}
