
import UiCompareSwiper from '@/components/ui/swipers/CompareSwiper'

export default {
  name: 'PageBuilderCompareSlider',
  components: {
    UiCompareSwiper
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
     items () {
       return this.section.slides
     }
  }
}
