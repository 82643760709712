
import UiButton from '@/components/ui/sections/Button'
export default {
  name: 'BuilderButtonCallToAction',
  components: {
    UiButton
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
