
import UiGallerySwiper from '@/components/ui/swipers/GallerySwiper'

export default {
  name: 'BuilderGallerySwiper',
  components: {
    UiGallerySwiper
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    }
  },
  computed: {
    images () {
      const images = this.section.images;
      let currentIndex = images.length; let  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [images[currentIndex], images[randomIndex]] = [
          images[randomIndex], images[currentIndex]];
      }

      return images
    }
  }
}
