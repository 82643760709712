
import UiImageGrid from '@/components/ui/grids/ImageGrid'

export default {
  name: 'BuilderLogoGrid',
  components: {
    UiImageGrid
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
  computed: {
    images () {
      return this.section.logos
        .filter(item => item?.logo)
        .map(item => {
          return item.logo
        })
    }
  }
}
