
import UiFeaturedBlogPosts from '@/components/ui/blog/FeaturedBlogPosts'

export default {
  name: 'BuilderBlogFeaturedPosts',
  components: {
    UiFeaturedBlogPosts
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
    posts () {
      return this.section.posts.map(post => post.blog_post)
    }
  }
}
