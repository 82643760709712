
import UiHeader from '@/components/ui/sections/Header'
import UiCareersList from '@/components/ui/careers/List'
export default {
  name: 'BuilderCareersList',
  components: {
    UiHeader,
    UiCareersList
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  }
}
