
import HeaderNavigation from '@/components/HeaderNavigation'

export default {
  name: 'DefaultLayout',
  components: {
    HeaderNavigation,
    FooterMain: () => import('@/components/FooterMain'),
    FooterBottom: () => import('@/components/FooterBottom'),
    // ScrollProgress: () => import('@/components/widgets/ScrollProgress')
  },
  data () {
    return {
      performanceScore: 'Loading...'
    }
  },
  head () {
    let normalisedPath = this.$route.path
    normalisedPath = normalisedPath.replace(/\/$/, '')
    normalisedPath = (normalisedPath.endsWith('/') ? normalisedPath.slice(0, -1) : normalisedPath)

    normalisedPath = normalisedPath.includes('/tags/') ? normalisedPath.slice(0, normalisedPath.indexOf('/tags/')) : normalisedPath

    return {
      titleTemplate: '%s - AYKO',
      link: [
        {
          rel: 'canonical',
          href: normalisedPath ? (this.$config.NUXT_URL + normalisedPath) : (this.$config.NUXT_URL + '/')
        }
      ]
    }
  },
  mounted () {
    if (typeof window !== 'undefined') {
      window.history.scrollRestoration = 'auto';
      document.addEventListener("DOMContentLoaded", () => {
        if (performance && performance.getEntriesByType) {
          const [navigationEntry] = performance.getEntriesByType("navigation");

          if (navigationEntry) {
            const duration = (navigationEntry.loadEventEnd - navigationEntry.startTime)
              .toLocaleString()
            this.performanceScore = `Loaded in ${duration}ms`
          } else {
            console.warn("Navigation timing is not available.");
          }
        } else {
          console.warn("Performance API is not supported in this browser.");
        }
      });
    }
  },
}
