
import UiTextAndImage from '@/components/ui/sections/TextAndImage'

export default {
  name: 'BuilderTextAndImage',
  components: {
    UiTextAndImage
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
