
import UiSectionsColourPalette from '@/components/ui/sections/ColourPalette'

export default {
  name: 'BuilderContentColourPalette',
  components: {
    UiSectionsColourPalette
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
  computed: {
    colours () {
      return this.section?.colour_code.map(item => {
        return item.hexcode
      })
    }
  }
}
